<template>
  <div class="ad-plan-form-container">
    <a-form-model ref="adPlanForm" :model="adplanform" v-bind="layout">
      <a-form-model-item prop="adType" class="adtype_radio" style="border-bottom: 1px solid #ccc">
        <div class="weight_label" style="">账号分类</div>
        <a-radio-group v-model="isAdminNum">
          <!-- 1:管理员 2: 媒体 -->
          <a-radio
            v-if="isAdd || (!isAdd && Number(isAdminNum) === 1)"
            :disabled="isDisabled"
            :value="1"
            :class="{ adtype_radio_item: true, active: Number(isAdminNum) === 1 }"
          >
            <div class="adtype_radio_box">
              <div class="left">
                <span class="title">管理员账号</span>
                <span class="desc">创建新的独立管理员</span>
                <span class="desc">管理员账号拥有最高权限</span>
              </div>
              <div class="right">
                <img style="width: 50px" :src="imgUrl1" alt="" />
              </div>
            </div>
          </a-radio>
          <a-radio
            v-if="isAdd || (!isAdd && Number(isAdminNum) === 2)"
            :value="2"
            :disabled="isDisabled"
            :class="{ adtype_radio_item: true, active: Number(isAdminNum) === 2 }"
          >
            <div class="adtype_radio_box">
              <div class="left">
                <span class="title">媒体账号</span>
                <span class="desc">创建合作媒体报表查询账号</span>
                <span class="desc">主要用于数据结算</span>
              </div>
              <div class="right">
                <img style="width: 50px" :src="imgUrl2" alt="" />
              </div>
            </div>
          </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item>
        <span class="weight_label" style="padding-top: 0; padding-bottom: 0">账号设置</span>
      </a-form-model-item>
      <!-- 账号名称 -->
      <div class="form_item">
        <a-form-model-item
          label="账号名称"
          prop="name"
          :rules="[{ required: true, message: '请输入账号名称', trigger: 'blur' }]"
        >
          <a-input style="width: 400px" v-model.trim="adplanform.name" autocomplete="off" placeholder="请输入账号名称" />
        </a-form-model-item>
      </div>
      <!-- 登录邮箱  -->
      <div class="form_item">
        <a-form-model-item
          label="登陆邮箱"
          prop="loginId"
          :rules="[
            {
              required: true,
              message: '请输入登陆邮箱',
              trigger: 'blur'
            },
            {
              message: '登陆邮箱必须为邮箱格式',
              trigger: 'blur',
              pattern: /^[A-Za-zd0-9_-]+([-_.][A-Za-zd0-9_-]+)*@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
            }
          ]"
        >
          <a-input style="width: 400px" v-model.trim="adplanform.loginId" autocomplete="off" placeholder="请输入登陆邮箱" />
        </a-form-model-item>
      </div>
      <!-- 登录密码 -->
      <div class="form_item" v-if="isAdd">
        <a-form-model-item
          label="登录密码"
          prop="password"
          :rules="[{ required: true, trigger: 'blur', validator: validatePass }]"
        >
          <a-input
            v-model.trim="adplanform.password"
            style="width: 400px"
            ref="password"
            :type="isShow ? '' : 'password'"
            autocomplete="new-password"
            placeholder="请输入登录密码"
          >
            <a-icon style="cursor: pointer" @click="changeIsShow" slot="suffix" type="eye" />
          </a-input>
        </a-form-model-item>
      </div>
      <!-- 联系人 -->
      <div class="form_item">
        <a-form-model-item
          label="联系人"
          prop="contact"
          :rules="[{ required: true, message: '请输入联系人', trigger: 'blur' }]"
        >
          <a-input style="width: 400px" v-model.trim="adplanform.contact" autocomplete="off" placeholder="请输入联系人" />
        </a-form-model-item>
      </div>
      <!-- 联系方式 -->
      <div class="form_item">
        <a-form-model-item
          label="联系方式"
          prop="mobile"
          :rules="[
            {
              required: true,
              message: '请填写联系方式',
              trigger: 'blur'
            },
            {
              message: '手机号无效',
              trigger: 'blur',
              pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
            }
          ]"
        >
          <a-input style="width: 400px" v-model.trim="adplanform.mobile" autocomplete="off" placeholder="请输入联系方式" />
        </a-form-model-item>
      </div>
      <!-- 公司名称 -->
      <div class="form_item">
        <a-form-model-item
          label="公司名称"
          prop="company"
          :rules="[{ required: true, message: '请输入公司名称', trigger: 'blur' }]"
        >
          <a-input style="width: 400px" v-model.trim="adplanform.company" autocomplete="off" placeholder="请输入公司名称" />
        </a-form-model-item>
      </div>
      <!-- 主营应用 -->
      <!-- <div class="form_item">
        <a-form-model-item
          label="主营应用"
          prop="mainApp"
        >
          <a-input style="width: 400px" v-model.trim="adplanform.mainApp" autocomplete="off" placeholder="请输入主营应用" />
        </a-form-model-item>
      </div> -->
      <!-- 开关状态 -->
      <div class="form_item" v-if="!isAdd">
        <a-form-model-item label="状态">
          <a-radio-group v-model="adplanform.sts">
            <a-radio value="A"> 开启 </a-radio>
            <a-radio value="S"> 关闭 </a-radio>
          </a-radio-group>
        </a-form-model-item>
      </div>
      <!-- 关联应用 管理员无此选项 超管登录时 管理员有此项-->
      <template>
        <div class="form_item">
          <a-form-model-item label="关联应用" prop="appList">
            <!-- <a-select
              style="width: 400px"
              v-model="adplanform.appList"
              @change="changApp"
              mode="multiple"
              :maxTagCount="1"
              allowClear
              placeholder="请选择关联应用"
            >
              <a-select-option v-for="item in appLists" :key="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select> -->
            <m-select-more
              style="width: 400px"
              v-model="adplanform.appList"
              :allData="appLists"
              :searchById="true"
              :hasIcon="true"
              :showLabel="false"
              :showId="true"
              label="应用"
              @change="changApp"
            />
          </a-form-model-item>
        </div>
      </template>
    </a-form-model>
  </div>
</template>

<script>
import { addAccount, updateAccount, getAccountCustomByUserId, getAppListByUser } from '@/api/system'
import { Encrypt } from '@/utils/jsencrypt'
import { mapState } from 'vuex'
export default {
  inject: ['changeLoading', 'isAdmin'],
  data () {
    return {
      layout: {
        labelCol: { span: 3 },
        wrapperCol: { span: 21 }
      },
      imgUrl1: require('@/assets/images/account1.png'),
      imgUrl2: require('@/assets/images/account2.png'),
      steps: 1,
      isAdd: false,
      adplanform: {
        isAdmin: 1,
        sts: 'A',
        appList: []
      },
      isShow: false,
      isDisabled: true,
      appLists: [],
      isAdminNum: 1
    }
  },
  computed: {
    ...mapState({
      role: (state) => state.user.roles[0]
    })
  },
  watch: {
    $route: {
      handler (val) {
        this.steps = val && val.query && +val.query.steps
        this.isAdd = val.query.type === 'add'
        this.isDisabled = val.query.type !== 'add'
        if (val.query.id) {
          this.getSelecInfo(val.query.id)
          this.adplanform.id = val.query.id
        }
      },
      immediate: true,
      deep: true
    },
    isAdminNum: {
      handler (newValue, oldValue) {
        if (newValue) {
          this.changAppList()
        }
      },
      deep: true
    }
  },
  mounted () {
    this.changAppList()
  },
  methods: {
    // 选择应用
    changApp (role) {
      const obj = JSON.parse(JSON.stringify(this.adplanform))
      obj.appList = role
      this.adplanform = obj
    },
    // 修改 获取应用
    async changAppList () {
      const { id = '' } = this.adplanform
      const obj = !this.isAdd ? { isAdmin: this.isAdminNum, id } : { isAdmin: this.isAdminNum }
      this.isAdmin(this.isAdminNum)
      const resp = await getAppListByUser(obj)
      this.appLists = resp.data || []
      if (!this.isAdd) {
        const arr = resp.data.filter((item) => item.isBinding).map((item) => item.id)
        this.adplanform.appList = arr
      }
    },
    // 编辑时根据id获取选择的账号信息
    async getSelecInfo (id) {
      const res = await getAccountCustomByUserId({ id })
      if (res.code === 200) {
        this.adplanform = res.data.user
        this.isAdminNum = res.data.user.isAdmin
      }
    },
    // 点击显示密码
    changeIsShow () {
      this.isShow = !this.isShow
      this.$refs.password.focus()
    },
    // 保存
    handleSubmitAdPlan () {
      return new Promise((resolve) => {
        this.$refs.adPlanForm.validate(async (valid) => {
          if (valid) {
            this.adplanform.isAdmin = this.isAdd ? this.isAdminNum : this.adplanform.isAdmin
            this.adplanform.password = Encrypt(this.adplanform.password)
            this.changeLoading(true)
            if (this.isAdd) {
              const resp = await addAccount(this.adplanform)
              resp.code === 200 && resolve(resp)
              setTimeout(() => {
                this.changeLoading(false)
              }, 1000)
            } else {
              const resp = await updateAccount(this.adplanform)
              resp.code === 200 && resolve(resp)
              this.changeLoading(false)
            }
          }
        })
      })
    },
    // 登录密码校验
    validatePass (rule, value, callback) {
      if (value === '' || value === undefined) {
        callback(new Error('请重新输入登录密码'))
      } else {
        if (value.length < 8) {
          callback(new Error('密码不低于8位'))
        } else {
          callback()
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/mixins/redefineAnt.less';
.ad-plan-form-container {
  .form_item {
    padding-left: 15px;
    margin-bottom: 20px;
    .form_item_label {
      margin-bottom: 10px;
      display: inline-block;
    }
  }
  .weight_label {
    font-size: 16px;
    color: #333;
    font-weight: 600;
    vertical-align: top;
    margin-right: 10px;
    padding: 0 15px;
  }
  .adtype_radio_item {
    margin-right: 20px;
    transition: all 0.8s;
    &:hover {
      transform: scale(1.03);
    }
    &.active,
    :hover {
      color: @primary-color;
      .adtype_radio_box {
        border: 1px solid @primary-color;
        .left {
          span {
            &.title {
              color: @primary-color !important;
            }
          }
        }
      }
    }
    .adtype_radio_box {
      width: 250px;
      height: 100px;
      border-radius: 10px;
      border: 1px solid;
      display: flex;
      margin-left: 15px;
      .left {
        width: 150px;
        padding: 10px 0 0 20px;
        display: flex;
        flex-direction: column;
        span {
          font-size: 12px;
          line-height: 25px;
          &.title {
            color: #333;
            font-size: 14px;
            font-weight: 600;
          }
          &.desc {
            margin-top: 10px;
            color: #999;
            line-height: 14px;
          }
        }
      }
      .right {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 30px;
      }
    }
  }
}
::v-deep.ad-plan-form-container {
  .adtype_radio span.ant-radio {
    opacity: 0;
  }
  .aRadioBtn();
  .ant-form-item-label {
    text-align: left;
  }
}
</style>
